@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  top: 0px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

.title, .name {
  background: linear-gradient(to right, #ff3110 , #00fcff);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.projectImage {
  transition: transform .2s;
  cursor: pointer;
}

.project:hover {
  * .projectImage {
    opacity: 40%;
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  * .readMoreImage {
    visibility: visible;
    display: block;
    pointer-events: none;
  }
}

.projectImage {
  transition: transform .2s;
  cursor: pointer;
}

.projectLink {
  transition: transform .2s;
}

.projectLink:hover {
  * {
    fill: #0b66c2;
    color: #0b66c2;
    transition: transform .2s;
    text-decoration: underline;
  }

  cursor: pointer;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes blink {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes typing {
  from {width: 0px}
  to {width: 165px}
}

@keyframes slide {
  from {margin-left: -5px}
  to {margin-left: 5px}
}

@keyframes slideIn {
  from {top: 0px;}
  to {top: 50%;}
}

@keyframes slideOut {
  from {top: 50%;}
  to {top: 0px;}
}

.experienceCard {
  max-height: 0;
  visibility: visible;
  overflow: hidden;
  transition: all 0.4s;
  * {
    transition: all 0.3s;
  }
}

.emailCTAInner {
  transition: all 0.1s;
}

.emailCTA:hover {
  * {
    height: 32px;
    margin-top: -34px;
    transition: all 0.1s;
  }
}

.ctas {
  transition: all 0.4s;
}

.ctas:hover {
  transition: all 0.4s;
  width: 60px;
  height: 60px;
}

@keyframes sunrise {
  from {
    transform: rotate(-45deg);
  }

  to {
    transform: rotate(315deg);
  }
}

@keyframes moonrise {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes dawn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}

@keyframes noon {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
}

@keyframes dusk {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@keyframes midnight {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes fadesun {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fademoon {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  --animation-speed: 24s;
  background-color: rgb(37, 29, 24);
}

body.pause {
  --animation-speed: 0;
}

.sky {
  position: relative;
  width: 100vw;
  height: auto;
  top: 0;
  left: 0;
}

.sky__phase {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 2px;
  width: 100%;
  transition: opacity 0.2s;
}

.sky__dawn {
  background: linear-gradient(
    0deg,
    rgba(254, 215, 102, 1) 0%,
    rgba(205, 237, 246, 1) 100%
  );
  animation: linear dawn infinite var(--animation-speed);
}

.sky__noon {
  background: linear-gradient(
    0deg,
    rgba(205, 237, 246, 1) 0%,
    rgba(36, 123, 160, 1) 100%
  );
  animation: linear noon infinite var(--animation-speed);
}

.sky__dusk {
  background: linear-gradient(
    0deg,
    rgba(255, 32, 110, 1) 0%,
    rgba(10, 0, 94, 1) 100%
  );
  animation: linear dusk infinite var(--animation-speed);
}

.sky__midnight {
  background: linear-gradient(
    0deg,
    rgba(2, 0, 20, 1) 0%,
    rgba(10, 0, 94, 1) 100%
  );
  animation: linear midnight infinite var(--animation-speed);
}

.orbit {
  width: 500px;
  height: 500px;
  margin: 200px auto;
  animation: linear sunrise infinite var(--animation-speed);
  top: 0px;

}

@media (min-width: 768px) {
  .orbit {
    width: 700px;
    height: 700px;
    margin: 150px auto;
  }
}

@media (min-width: 940px) {
  .orbit {
    width: 800px;
    height: 800px;
  }
}

@media (min-width: 1200px) {
  body {
    --animation-speed: 28s;
  }
  .orbit {
    width: 1000px;
    height: 1000px;
    margin: 200px auto;
  }
}

@media (min-width: 1500px) {
  body {
    --animation-speed: 30s;
  }
  .orbit {
    width: 1300px;
    height: 1300px;
  }
}

.sun {
  position: absolute;
  top: -40px;
  left: -40px;
  width: 80px;
  height: 80px;
  background-color: rgb(254, 215, 102);
  border-radius: 50%;
  box-shadow: 0 0 14px 14px rgba(254, 215, 102, 0.2);
  animation: fadesun 28s steps(2, jump-none) infinite;
}

.moon {
  position: absolute;
  bottom: -40px;
  right: -40px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 7px 7px rgba(255, 255, 255, 0.2);
  animation: fademoon 28s steps(2, jump-none) infinite;
}

#sky__stars > div {
  width: 3px;
  height: 3px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
}

#toggle-animation {
  position: fixed;
  bottom: 10em;
  right: 1em;
  text-transform: uppercase;
  background-color: rgb(2, 0, 20);
  color: #fff;
  border: 0;
  padding: 0.5em 1em;
  letter-spacing: 0.5px;
}

#toggle-animation:hover {
  background-color: rgb(61, 0, 21);
  cursor: pointer;
}

.paused {
  display: none;
}

.pause .paused {
  display: block;
}

.pause .playing {
  display: none;
}
